<style src="./index.less" lang="less" scoped></style>
<template src="./index.html"></template>
<script>
import { GetReturnUserInfo, GetUpdateUserInfo } from '@/api/returnOrder';
export default {
  name: "info",
  data() {
    return {
      name      : '',
      account   : '',
      invitation: '',
      mobile    : '',
      tipsDialog: {
        show    : false,
        account : '',
      },
    };
  },
  methods: {
    phoneVerify() {
      if (!this.mobile) {
        return false;
      }
      return /^1[3456789]\d{9}$/.test(this.mobile);
    },
    getBtnClass() {
      if (this.phoneVerify() && this.name && this.account && this.invitation) {
        return 'btn-primary'
      } else {
        return 'btn-disabled'
      }
    },
    async onSave() {
      if (this.phoneVerify() && this.name && this.account && this.invitation) {
        let { code, message, data } =  await GetUpdateUserInfo({
          company_name  : this.name,
          zfb_account   : this.account,
          company_mobile: this.mobile,
          invitation    : this.invitation
        })
        if (code === 0) {
          switch (data.type){
            case 0:
              this.$message.success('保存成功')
              break;
            case 1:
              this.$message.error('请输入正确的身份ID')
              break;
            case 2:
              this.tipsDialog = {
                  show    : true,
                  account : data.alipay_account,
              }
          }
        } else {
          this.$message.error(message)
        }
      }else{
        this.$message.error('请将信息填写完整')
      }
    },
    initForm(){
      GetReturnUserInfo().then(res => {
        let { code, data, message } = res;
        if(code === 0){
          this.name       = data.company_name
          this.account    = data.zfb_account
          this.invitation = data.invitation
          this.mobile     = data.company_mobile
          return
        }
        this.$message.error(message)
      })
    }
  },
  mounted() {
    this.initForm()
  }
}
</script>
<style lang="less">
  .commission-dialog{
    .el-dialog__header{ display: none; }
    .el-dialog__body{ background: white; border-radius: 5px; }
    &-title{
      font-weight: 500; line-height: 24px; font-size: 16px; position: relative; padding-left: 30px; margin-bottom: 10px; color: #252931;
      img{ position: absolute; left: 0; top: 50%; width: 20px; margin-top: -10px; }
      i.close-btn{ color:#333; position: absolute; right: 0; top: 0; font-size: 20px; }
    }
    &-content{ font-size: 14px; line-height: 20px; font-weight: 400; padding-left: 30px; }
    &-footer{
      text-align: right; padding-top: 20px;
      button{
        margin-left: 16px; height: 32px; padding: 0 20px; border:solid 1px #DCDEE1; border-radius: 4px; background: white; font-size: 14px; color: #222;
        &:first-child{margin-left: 0;}
        &.confirm-btn{ background: #FF791F; color: white; border: none; }
      }
    }
  }
</style>