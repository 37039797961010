
import http from "../plugins/axios"

// 返佣开关接口
export const rebateSwitch = (data) => http('post', '/KsExpress/rebate_switch', data)
// 基础信息回显
export const GetReturnUserInfo = () => http('post', '/KsExpress/return_user_info')
// 修改用户信息
export const GetUpdateUserInfo = (data) => http('post', '/KsExpress/update_user_info', data)
// 退货订单明细
export const returnOrderDetails = (data) => http('post', '/KsExpress/return_order_details', data)
// 退货订单列表
export const returnOrderList = (data) => http('post', '/KsExpress/return_order_list', data)
// 退货订单列表导出
export const returnOrderListImport = (data) => http('post', '/KsExpress/return_order_list_import', data)
//退货返佣汇总列表
export const returnedDetail = (data) => http('post', '/KsExpress/commission_returned_detail', data)