import request from '../plugins/axios.js'

// 示例方法

// 1.不带参数get请求，直接将方法导出
export function testGetWithoutParam() {
  return request(
    // 第一个参数：get/post
    'get',
    // 第二个参数：接口地址，这里和baseURL拼接
    'artical/articallist'
  )
}

// 2.带参数post请求，直接将方法导出
export function testPostWithParam( params ) {
  return request(
    'post',
    'artical/articalinfo',
    // 第三个参数：参数，如果没有参数可以直接不写第三个参数，已设置默认值为空
    params
  )
}

export function testToken( ) {
  return request(
    'post',
    'Login/Test?id=220'
  )
}
