<style scoped lang="less">
.service_box {
    cursor: pointer;
    position: fixed;
    left: 30px;
    bottom: 46px;
    text-align: center;
}

.service_box:hover .service_info {
    display: block;
}

.service_img {
    width: 37px;
    height: 39px;
}

.service_text {
    z-index: 2;
    position: relative;
    margin-top: -7px;
    padding: 2px 3px;
    border-radius: 16px;
    color: #fff;
    background-color: #F6891E;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
}

.service_info {
    position: absolute;
    top: -32px;
    left: 46px;
    width: 168px;
    height: 102px;
    display: none;

    .service_info_text {
        position: absolute;
        right: 16px;
        bottom: 16px;
        background-color: #FFECE5;
        color: #252931;
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        width: 125px;
        height: 34px;
        line-height: 34px;
        border-radius: 4px;
    }
}
</style>
<template>
    <div style="position: fixed;">
        <div class="service_box">
            <img :src="OSS + '/service.png'" class="service_img">
            <div class="service_text">找客服</div>
            <div class="service_info">
                <img :src="OSS + '/service_info_img.png'" style="width: 100%;height: 100%;">
                <div class="service_info_text">13263622277</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'service',

}
</script>